import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { NewsService } from '../service/news.service';
import { ContactService } from '../service/contact.service';
import { BrowserModule, Title } from '@angular/platform-browser';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Contact } from '../_models/contact.model';
import { Global } from '../global';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  @ViewChild("successAlert", { static: true }) successAlert: ElementRef;
  @ViewChild("failAlert", { static: true }) failAlert: ElementRef;

  contactForm: FormGroup;
  loading: boolean = false;
  submitted: boolean = false;

  newsList: any;

  g: Global = new Global();

  constructor(private router: Router,
    private newsService: NewsService,
    private titleService: Title,
    private formBuilder: FormBuilder,
    private contactService: ContactService) {
      titleService.setTitle("Home | OneStop Logistics");
  }

  ngOnInit() {
    this.newsList = this.newsService.get().slice(0, 2);

    this.contactForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      subject: ['', Validators.required],
      details: ['', Validators.required]
    });

    this.successAlert.nativeElement.hidden = true;
    this.failAlert.nativeElement.hidden = true;

    /******SCROLL TO TOP WHEN ROUTE CHANGED******/
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0)
    });
  }  

  get f() { return this.contactForm.controls; }

  clearFields() {
    this.f.firstName.setValue("");
    this.f.lastName.setValue("");
    this.f.email.setValue("");
    this.f.subject.setValue("");
    this.f.details.setValue("");
  }

  onSubmit() {
    this.submitted = true;
    this.loading = true;

    if (this.contactForm.invalid) {
      this.loading = false;
      return;
    }

    var form = this.contactForm.value;

    var contact = new Contact();
    contact.fullName = form.firstName + " " + form.lastName;
    contact.email = form.email;
    contact.subject = form.subject;
    contact.details = form.details;

    this.contactService.postContact(contact)
      .subscribe(
        (res) => {
          this.submitted = false;
          this.loading = false;
          this.successAlert.nativeElement.hidden = false;
          setTimeout(() => {
            this.successAlert.nativeElement.hidden = true;
            this.clearFields();
          }, 3000);

        },
        (error) => {
          this.submitted = false;
          this.loading = false;
          this.failAlert.nativeElement.hidden = false;
        }
      );
  }
}
