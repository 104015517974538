import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-faqs',
  templateUrl: './faqs.component.html',
  styleUrls: ['./faqs.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class FaqsComponent implements OnInit {
  constructor(private titleService: Title) {
    titleService.setTitle("FAQs | One Stop Logistics");
  }

  ngOnInit() {
  }

}
