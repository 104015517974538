import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-site-map',
  templateUrl: './site-map.component.html',
  styleUrls: ['./site-map.component.css']
})
export class SiteMapComponent implements OnInit {

  constructor(private titleService: Title) {
    titleService.setTitle("Sitemap | OneStop Logistics");
  }

  ngOnInit() {
  }

}
