import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { AppComponent } from './app.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { HomeComponent } from './home/home.component';
import { CounterComponent } from './counter/counter.component';
import { FetchDataComponent } from './fetch-data/fetch-data.component';
import { ExternalAppComponent } from './external-app/external-app.component';
import { BranchesComponent } from './branches/branches.component';
import { HeroComponent } from './hero/hero.component';
import { AboutComponent } from './about/about.component';
import { OurServicesComponent } from './our-services/our-services.component';
import { CareersComponent } from './careers/careers.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { NewsService } from './service/news.service';
import { NewsArticleComponent } from './news-article/news-article.component';
import { NewsAnnouncementsComponent } from './news-announcements/news-announcements.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';
import { SiteMapComponent } from './site-map/site-map.component';
import { FaqsComponent } from './faqs/faqs.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatExpansionModule } from '@angular/material/expansion';
import { NpcComponent } from './npc/npc.component';

@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    HomeComponent,
    CounterComponent,
    FetchDataComponent,
    ExternalAppComponent,
    BranchesComponent,
    HeroComponent,
    AboutComponent,
    OurServicesComponent,
    CareersComponent,
    ContactUsComponent,
    NewsArticleComponent,
    NewsAnnouncementsComponent,
    PrivacyPolicyComponent,
    TermsConditionsComponent,
    SiteMapComponent,
    FaqsComponent,
    NpcComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forRoot([
      { path: '', component: HomeComponent, pathMatch: 'full' },
      { path: 'our-services', component: OurServicesComponent },
      { path: 'about-us', component: AboutComponent },
      { path: 'careers', component: CareersComponent },
      { path: 'contact-us', component: ContactUsComponent },
      { path: 'news-update/:newsId/:newsTitle', component: NewsArticleComponent },
      { path: 'news-and-announcements', component: NewsAnnouncementsComponent },
      { path: 'faqs', component: FaqsComponent },
      { path: 'privacy-policy', component: PrivacyPolicyComponent },
      { path: 'terms-and-conditions', component: TermsConditionsComponent },
      { path: 'site-map', component: SiteMapComponent }
    ]),
    BrowserAnimationsModule,
    MatExpansionModule 
  ],
  providers: [NewsService, Title],
  bootstrap: [AppComponent]
})
export class AppModule { }
