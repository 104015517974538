import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/internal/operators';

import { Contact } from '../_models/contact.model';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class ContactService {
  localUrl = "";

  constructor(
    private http: HttpClient,
  ) {
    this.localUrl = location.origin;
  }

  postContact(contact: Contact) {
    var postUrl = `${this.localUrl}/api/Contact`;
    return this.http.post<Contact>(postUrl, contact, httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  handleError(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }

    console.log("applicant service - errorMessage", errorMessage);
    return throwError(errorMessage);
  }
}
