export class Global {

  constructor() {
    return {
       spaceToUnderscore: function(text: string) {
        return text.replace(/\s/g, '_');
       }
    }
  }
}
