import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { NewsService } from '../service/news.service';
import { Title } from '@angular/platform-browser';
import { Global } from '../global';

@Component({
  selector: 'app-news-announcements',
  templateUrl: './news-announcements.component.html',
  styleUrls: ['./news-announcements.component.css']
})
export class NewsAnnouncementsComponent implements OnInit {

  newsList: any;
  g: Global = new Global();

  constructor(private router: Router,
    private newsService: NewsService,
    private titleService: Title) {
    titleService.setTitle("News and Announcements | OneStop Logistics");
  }

  ngOnInit() {
    this.newsList = this.newsService.get();

    /******SCROLL TO TOP WHEN ROUTE CHANGED******/
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0)
    });

  }  
 }

