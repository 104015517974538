import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router, NavigationEnd } from '@angular/router';
import { NewsService } from '../service/news.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-news-article',
  templateUrl: './news-article.component.html',
  styleUrls: ['./news-article.component.css']
})
export class NewsArticleComponent implements OnInit {

  news_newsId: number;
  newsDetails: any;

  constructor(private actRoute: ActivatedRoute,
    private router: Router,
    private newsService: NewsService,
    private titleService: Title) {

    titleService.setTitle("OLSI News | OneStop Logistics");

    this.news_newsId = this.actRoute.snapshot.params.id;
  }

  ngOnInit() {
    this.actRoute.paramMap.subscribe(params => {
      this.newsDetails = this.newsService.getById(+params.get('newsId'));
    });

    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0)
    });
  }

}
